body {
  margin: 0;
  padding: 10px 10px;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (min-width: 1280px) {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 20px;
  }
}

/* Global CSS */
html {
  scroll-behavior: smooth;
    height: 100%;
    background: linear-gradient(to bottom, #222a57, #0000007c);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: black;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  display: none;
}